.select-component {
  &__control {
    background-color: var(--kt-input-solid-bg) !important;
    border-color: var(--kt-input-solid-bg) !important;
    color: var(--kt-input-solid-color) !important;
    transition: color 0.2s ease !important;
    box-shadow: none !important ;
    min-height: calc(1.5em + 1.65rem + 2px) !important;
    font-size: 1.15rem !important;
    border-radius: $btn-border-radius-lg !important;

    &--is-focused{
      box-shadow: none !important;
      background-color: var(--kt-input-solid-bg-focus) !important;
      border-color: var(--kt-input-solid-bg-focus) !important;
      color: var(--kt-input-solid-color) !important;
      transition: color 0.2s ease !important;
    }
  }

  &__value-container{
    padding: 0px 1.5rem !important;
  }

  &__menu,
  &__option{
    background-color: var(--kt-input-solid-bg) !important;
    color: var(--kt-input-solid-color) !important;
  }
  &__option{
    border-color: var(--kt-input-solid-bg) !important;
  }

  &__option:hover{
    background-color: rgba(186, 209, 218, 0.329) !important;
  }

  &__placeholder{
    color: var(--kt-gray-500) !important;
  }
  &__single-value{
    color: var(--kt-input-solid-color) !important;
  }
  &__control--is-focused{
    background-color: var(--kt-input-solid-bg) !important;
  }
}
