//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "layout/layout";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}