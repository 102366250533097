.react-datepicker__input-container {
  input {
    width: 100%;
    padding: 0px 1.5rem !important;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  button:after{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cccccc;
    height: 20px;
    width: 20px;
    font-size: 20px;
    line-height: 0;
    content: "×";
  }
}

.custom-date-picker {
  &.only-date{
    max-width: 150px !important;
  }
  &.with-hours{
    max-width: 300px !important;
  }
  input{
    color: var(--kt-input-solid-color) !important;
    font-size: 1.15rem !important;
    transition: color 0.2s ease !important;
    border-radius: 0.85rem !important;
    font-weight: 500;
  }
}
