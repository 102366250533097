
.order-item {
  .card-header {
    .card-toolbar {
      .knob-icon {
        cursor: grab;
      }
      .delete-step-icon {
        display: none !important;
      }
    }
  }
  .card-body {
    padding: 0px 2.25rem 2rem !important;
  }

  &:hover {
    .card-header .card-toolbar .delete-step-icon {
      display: inline-flex !important;
    }
  }
}
.order-item:not(:first-child) {
  margin-top: 10px;
}