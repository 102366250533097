.table-pagination{
  padding: 0 2.25rem  2.25rem;
}
.page-count {
  // max-width: 30px;
  display: flex;
  align-items: center;

  input{
    max-width: 2.5rem;
    height: 100%;
    margin-right: 5px;
    text-align: end;
    padding-right: 8px !important;
  }
}
[data-theme=dark] .page-count input{
  background-color: #09092370 !important;
  color: #9595c7 !important;
}
.dataTable {
  tr:hover{
    background-color: #fff9eca1;
  }
}
[data-theme=dark] .dataTable {
  thead tr:hover{
    background-color: var(--bs-table-bg) !important;
  }
}
td.select{
  max-width: 53px;
  width: 53px;
}
.empty-row{
  text-align: center;
  padding: 40px !important;
}
.table.table-row-dashed tr{
  cursor: pointer;
}