.body-part-tabs {
  overflow-x: auto;
  flex-wrap: nowrap !important;
}
.exercise-add-delete-btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  right: 10px;
  top: 10px;
  background: var(--kt-body-bg);
  padding: 5px 0px;
  border-radius: 10px;
  border: 1px solid rgba(181, 181, 195, 0.7);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  button {
    width: 20px !important;
    height: 20px !important;
  }
}

.basket-item {
  border: 1px solid var(--kt-border-color);
  padding: 10px;
  width: 100%;
  border-radius: 10px;

  &-sortable-knob {
    border-radius: 50%;
    padding: 5px 5px;
    margin-right: 8px;
  }
  &-edit-btn {
    height: 28px !important;
    width: 28px !important;

    svg {
      width: 1.5rem !important;
      height: 1.5rem !important;
      max-height: 24px !important;
    }
  }

  &-edit-content {
    border-top: 1px solid var(--kt-border-color);
    margin-top: 10px;
    padding-top: 10px;
  }
}
